
/* General Portfolio Style */
.portfolio .navbar-brand {
  color: var(--text);
}

.portfolio .navbar-brand:hover {
  color: var(--text);
}

.portfolio-section{
  padding-top:1rem;
   min-height: 100vh;
    position: relative;
    scroll-margin-top: 4rem;
}

@media only screen and (max-width: 768px) {
  .portfolio-section {
    scroll-margin-top: 9rem;
  }
}

/* Summary Page Style */
#social-container {
  position: relative;
}

#summary {
  padding-top: 0em;
}

#social-container> .seperator {
  position: absolute;
  width: 200%;
  height: 200%;
  background-color: var(--primary);
  transform: rotate(-10deg) translate(-50%, -50%);
  z-index: -1;
}

#summary > .row {
  min-height: 100vh;
  overflow: hidden;
  margin-right: 0;
}

#summary > h1 {
  position: absolute;
  margin-top: 0.5em;
  left:33%;
}

#summary-description {
  margin-top:5rem;
}

@media only screen and (max-width: 768px) {

  #social-container {
    position: relative;
    margin-top: 3em;
  }

  #summary > h1 {
    position: absolute;
    left: 0%;
    margin-left: 1rem;

  }

  #social-container> .seperator {
      position: absolute;
      width: 250%;
      height: 200%;
      background-color: var(--primary);
      transform: rotate(-5deg) translate(-50%, -60%);
      z-index: -1;
  }
}


/* Projects Section Style */
#projects {
  padding-top: 0;
 
}

#projects > h1 {
  padding: 0.5rem;
  background-color: var(--primary);
  text-align: center;
  margin-bottom: 0;
}

.slide-rotate {
  transform: rotate(5deg);
}

.slider {
  width: 100%;
  min-height: 400px;
  height: 60vh;
  position: relative;
  overflow: hidden;
  background-color: var(--neutral);
  z-index: 2;
}

.slide {
  width: 100%;
  height:350px;
  position: absolute;
  transition: all 0.5s;
}

.slide .card {

  background-color: var(--background);
  color: var(--text);
  border: none;
  border-radius: 0.2rem;
}

.slide .card-img-top {
  width: 100%;
  height: 210px;
  object-fit: cover;
  background-color: var(--text-light);
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  border: none;
}

 .slide-footer {
  overflow-x:hidden;
}

#projects .slide-footer > div {
  width: 110%;
  height: 15vh;
  background-color: var(--primary);
  transform: rotate(2deg) translate(-5%,-50%);
}

/* Experience Section Style */
#experience {
  padding-bottom: 8rem;
}

#experience .card-subtitle {
    color: var(--text);
    opacity: 0.5;
    font-size: smaller;
}


.timeline-line{
  position: absolute;
 width:0.5rem;
 height: 100%;
 border-bottom-left-radius: 0.2rem;
 border-top-left-radius: 0.2rem;
 background-color: var(--accent);
}

.timeline-item{
  position: relative;
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  background-color: var(--secondary);
}

.cert-item {
  border: none;
  background-color: var(--secondary);
  border-radius: 0.2rem;
}

.timeline-item::before{
  position: absolute;
  top:2rem;
  left: -2%;
  content:"";
  width: 1rem;
  height: 1rem;

  background-color: var(--background);
  border-color: var(--accent);
  border-width: 0.2rem;
  border-style: solid;
  border-radius: 50%;
}


/* Knowedge Section Styles */
#knowledge {
  border-top: solid 1em var(--primary); 
  padding-top: 0;
  background-color: var(--neutral);
}

#knowledge  h1 {
  padding: 0.5rem;
  background-color: var(--primary);
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

#knowledge .card {
  background-color: transparent;
}

.skill-container {
  align-items: center;
  border-bottom: solid 0.1rem var(--primary);
}
.skill-content {
  justify-content: center;
}

.skill-title {
  min-width: fit-content;
  width:30%;
}

.image-icon {
  height:2em;
}


