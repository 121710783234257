

.project-view {
    overflow: hidden;
    position: relative;
}

header {
    padding: 2rem;
    background-color: var(--primary);
    margin-bottom: 7rem;
}
.header-banner {
    position: absolute;
    background-color: var(--primary);
    height: 15rem;
    width: 200%;
    transform: rotate(7deg) translate(-50%, 10%); 
    z-index: -1;
 }

.image-icon {
    height:2em;
  }

.card {
    border-radius: 0.2rem;
    border:none;
    background-color: var(--primary);
    color: var(--text);

}

.accordion {
    --bs-accordion-bg: var(--secondary);
    --bs-accordion-border-color: none;
    border-radius: 0.2rem;
    
    
}

.accordion-button {
    background-color: var(--primary);
    color: var(--contrast);
}

.accordion-button:hover {
    color: var(--accent-focus);
}

.accordion-button{
    color: var(--accent);
}

.accordion-button:focus {
    box-shadow: var(--accent);
}



.project-skills {
    position: relative;
    padding: 2rem;
    background-color: var(--neutral);
    margin-top: 10rem;
    color:var(--dim);
}
.project-skills-banner {
    position: absolute;
    background-color: var(--neutral);
    height: 15rem;
    width: 200%;
    transform: rotate(7deg) translate(-50%); 
    z-index: -1;
 }

 #links {
    background-color: var(--neutral);
 }
